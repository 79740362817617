import { Route } from "react-router-dom";
import { AdminEmailManagement, AdminManageEmail } from "./AdminEmailManagement";
import {
  AdminNumberScreen,
  CredentialTab,
  ImportTab,
  TaskTransferModel,
} from "./AdminTabs";
import { AdminAutomation } from "./AdminAutomation";
import AdminUsers from "./AdminUsers";
import ApiScreen, { ApiTab } from "./ApiScreen";
import AdminScreen from "./AdminScreen";
import { Fragment } from "react";
import AdminReports from "./AdminReports";
import { AdminLeadClients, AdminManageLeadClient } from "./AdminLeadClients";

export const AdminScreenTabs = [
  {
    value: "transfer_task",
    color: "green",
    disabled: false,
    component: <TaskTransferModel />,
  },
  {
    value: "number_management",
    color: "red",
    disabled: false,
    component: <AdminNumberScreen />,
  },
  {
    value: "user_management",
    color: "green",
    disabled: false,
    component: <AdminUsers />,
  },
  {
    value: "credential_management",
    color: "blue",
    disabled: false,
    component: <CredentialTab />,
  },
  {
    value: "right_management",
    color: "blue",
    disabled: true,
    component: null,
  },
  {
    value: "import",
    color: "blue",
    disabled: true,
    component: <ImportTab />,
  },
  {
    value: "api_management",
    color: "red",
    disabled: false,
    component: <ApiTab />,
    route: <ApiScreen />,
  },
  {
    value: "email_account_management",
    color: "red",
    disabled: false,
    component: <AdminEmailManagement />,
    route: <AdminManageEmail />,
  },
  {
    value: "automation",
    color: "red",
    disabled: true,
    component: <AdminAutomation />,
  },
  {
    value: "lead_clients",
    color: "red",
    disabled: false,
    component: <AdminLeadClients />,
    route: <AdminManageLeadClient />,

  },
];

export const AdminScreenRoutes = () => {
  return (
    <>
      <Route exct path="/admin/:tab" element={<AdminScreen />} />
      <Route exct path="/admin-reports/:tab" element={<AdminReports />} />
      {AdminScreenTabs.map((route) => (
        <Fragment key={route.value}>
          {route.disabled !== true && (
            <Route
              exact
              path={`/admin/${route.value.replace(/_/g, "-")}/:key`}
              element={route.route}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
